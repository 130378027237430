/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./resources/**/*.blade.php",
    "./resources/**/*.js",
    "./resources/**/*.jsx",
  ],
  safelist: [
    '!text-main',
    'h-[72px]',
    'max-h-[calc(100%-72px)]',
    'overflow-y-scroll',
    'overflow-hidden',
    'bg-backgroundSchedul2', // 門診時間後台產生的 class
  ],
  theme: {
    extend: {
      fontFamily: {
        'noto-sans': 'Noto Sans TC, auto'
      },
      colors: {
        // background
        main: '#68b448',
        main2: '#009da7',
        secondary: '#ace232',
        team1: '#d5ff40',
        team2: '#bee23e',
        team3: '#282828',
        orthok1: '#0091bf',
        orthok2: '#ecf5f9',
        orthok3: '#f6a05a',
        orthok4: '#f9f2de',
        orthok5: '#a1d55d',
        orthok6: '#e2eed6',
        orthok7: '#009aa7',
        cataract1: '#00a7b1',
        cataract2: '#dffff6',
        cataract3: '#f0f0f0',
        cataract4: '#c6d2d7',
        smile1: '#1f4598',
        smile2: '#51e669',
        optometry1: '#161616',
        optometry2: '#21c4d1',
        optometry3: '#33f18e',
        optometry4: '#7f8882',
        dryeye1: '#3c7379',
        dryeye2: '#e3ebec',
        dryeye3: '#2b4858',
        dryeye4: '#92a3ab',
        dryeye5: '#21cad6',
        case1: '#e4e4e4',
        case2: '#028d53',
        case3: '#79b45a',
        text1: '#00a3ad',
        knowledge: '#b0b0b0',
        teamText1: '#5e851f',
        reserveText1: '#56b88e',
        scheduleText1: '#79b95a',
        checkupText1: '#1a4e45',
        checkupText2: '#c6a87a',
        orthokText1: '#2a4858',
        orthokText2: '#61864d',
        scheduleActive: '#8df979',
        scheduleInActive: '#b4d291',
        face1: '#2960a8',
        face2: '#e56929',
        face3: '#8963a7',
        face4: '#7c7362',
        dark1: '#3a3a3a',
        background: '#fff8ef',
        background1: '#dcdddd',
        backgroundSchedul1: '#d3d3d3',
        backgroundSchedul2: '#efefef',
        backgroundSchedul3: '#e5e5e5',
        backgroundTeamBanner: '#79b15a',
        backgroundTeam1: '#e1e4e9',
        backgroundTeam2: '#c2df9d',
        backgroundCheckup1: '#c7e5a3',
        backgroundCheckup2: '#c6a87a',
        backgroundCheckup3: '#ededed',
        backgroundCheckup4: '#c0a27a',
        backgroundReserve1: '#efefef',
        backgroundReserve2: '#79b15a',
        backgroundReserve3: '#ffc439',
        backgroundReserve4: '#9accaa',
        backgroundReserve5: '#95e0b8',
        backgroundOrthok1: '#e2e2e2',
        backgroundOrthok2: '#8df979',
        backgroundCataract1: '#d1d1d1',
        backgroundCataract2: '#009fa9',
        backgroundCataract3: '#9cd7bf',
        backgroundSmile1: '#dcdcdc',
        backgroundSmile2: '#969696',
        backgroundSmile3: '#f0f0f0',
        backgroundSmile4: '#1f4494',
        backgroundOptometry1: '#ececec',
        backgroundOptometry2: '#019da6',
        backgroundOptometry3: '#f1f1f3',
        backgroundOptometry4: '#f3f4f5',
        backgroundOptometry5: '#79af5a',
        backgroundOptometry6: '#059da7',
        backgroundDryeye1: '#009fa9',
        backgroundCase1: '#058e53',
        backgroundEyecare1: '#79af5a',
        footerBackground: '#3e3a39'
      },
      gridTemplateColumns: {
        maxColumnAuto: 'max-column auto'
      }
    },
  },
  plugins: [],
}

